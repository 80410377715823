<template>
  <v-container class="second-footer-part px-0" :fluid="isSafari">
    <v-row>
      <v-col cols="12" lg="6" xl="7" class="px-lg-0">
        <v-row justify="center">
          <v-col class="text-left text-md-left pl-md-0" cols="12" md="6">
            <nuxt-link to="/">
              <NuxtImg
                width="150"
                provider="directus"
                class="align-self-center"
                alt="Containerdienst.de"
                src="8932063c-5a11-4ca1-88fe-8de66054f8b8"
                :modifiers="{ format: 'auto', width: 500 }" />
            </nuxt-link>
            <div class="d-block">
              <span class="footer-span">{{ footer.cde_text }}</span>
            </div>
          </v-col>

          <v-col class="text-left text-md-left mt-4 mt-md-0" cols="12" md="6">
            <span class="font-weight-black d-block">{{ footer.b2b_label }}</span>
            <span class="footer-span">{{ footer.b2b_text }}</span>
            <div class="justify-start justify-md-end">
              <button-secondary class="b2b-button" @click="goToPage(footer?.b2b_button_link || '')">
                {{ footer.b2b_button_label }}
              </button-secondary>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="pl-md-0 pl-xl-7 pr-md-0" cols="12" lg="6" xl="5">
        <v-row class="mt-4 mt-md-0">
          <v-col class="text-left text-md-left px-md-0 py-0" cols="12" md="4" lg="4">
            <span class="font-weight-black d-block">{{ footer.contact_label }}</span>

            <v-row class="pa-0 pt-3">
              <v-col
                v-for="contactInformation of contactInformationList"
                :key="contactInformation.icon"
                class="pa-0 pb-3"
                cols="12">
                <v-icon class="mr-2" :size="lgAndUp ? '16' : '14'">
                  {{ contactInformation.icon }}
                </v-icon>
                <span v-if="contactInformation.phone" class="footer-span">
                  <nuxt-link :href="'tel:' + contactInformation.phone.replace(/ /g, '')">
                    {{ contactInformation.text }}
                  </nuxt-link>
                </span>
                <span v-if="contactInformation.email" class="footer-span">
                  <nuxt-link :href="'mailto:' + contactInformation.email">
                    {{ contactInformation.text }}
                  </nuxt-link>
                </span>
                <span
                  v-if="!contactInformation.email && !contactInformation.phone"
                  class="footer-span">
                  {{ contactInformation.text }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-divider v-if="smAndDown" class="mt-2 mb-2 footer-divider" />

          <v-col class="text-center text-md-left py-0 mt-4 mt-md-0" cols="12" md="4" lg="4">
            <span v-if="!smAndDown" class="font-weight-black d-block">
              {{ t('secure_payment') }}
            </span>
            <v-row class="pt-1" justify="center" justify-md="start" :class="{ 'w-75': !smAndDown }">
              <v-col
                v-for="(icon, index) of paymentIcons"
                :key="icon"
                cols="3"
                sm="2"
                md="6"
                xl="6"
                class="px-0"
                :class="{ 'pt-0': index > 1 && !smAndDown }">
                <v-img
                  :width="smAndDown ? '' : '72px'"
                  height="42px"
                  :src="icon"
                  :alt="t('payment_methods')" />
              </v-col>
            </v-row>
          </v-col>

          <v-col class="py-0 mt-5 mt-md-0" cols="12" md="4" lg="4">
            <v-row justify="center" justify-md="end" class="mx-md-0">
              <v-col v-if="!smAndDown" cols="12" class="pa-0 pt-3">
                <span class="font-weight-black d-block text-right">
                  {{ t('quality_guarantee') }}
                </span>
              </v-col>
              <v-col cols="12" md="8" lg="12" xl="8" class="pa-0">
                <v-row justify="center" justify-md="end" class="pt-1">
                  <v-col
                    v-for="icon of qualityBadges"
                    :key="icon"
                    class="pa-0 mb-3 mt-3 d-flex justify-end"
                    cols="3"
                    sm="2"
                    md="6">
                    <v-img
                      :max-width="smAndDown ? '' : '70px'"
                      max-height="70px"
                      :src="icon"
                      :alt="t('certified')" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts" setup>
import { useDisplay } from 'vuetify'

import iconFooterQMSFachbetrieb from '@/assets/icons/footer/QMS_Entsorgungsfachbetrieb_1_zmart.png'
import iconFooterQMSManagementsystem from '@/assets/icons/footer/QMS_Managementsystem_1_zmart 2.png'
import iconFooterBcorp from '@/assets/icons/footer/footer_bcorp.svg'
import iconPaypal from '@/assets/icons/footer/paypal-frame.svg'
import iconRechnung from '@/assets/icons/footer/rechnung-frame.svg'
import iconVisa from '@/assets/icons/footer/visa-frame.svg'
import type { Footer } from '~/types/types'

const { t } = useI18n()
const { smAndDown, lgAndUp } = useDisplay()
const { isSafari } = useDevice()

const qualityBadges = [iconFooterBcorp, iconFooterQMSFachbetrieb, iconFooterQMSManagementsystem]

const paymentIcons = [iconPaypal, iconVisa, iconRechnung]
const props = defineProps({
  footer: {
    type: Object as PropType<Footer>,
    default: () => ({}),
  },
})
const { footer } = toRefs(props)
const contactInformationList = [
  {
    icon: 'mdi-clock-outline',
    text: footer.value.day_and_time,
  },
  {
    icon: 'mdi-phone-outline',
    text: footer.value.phone,
    phone: footer.value.phone,
  },
  {
    icon: 'mdi-email-outline',
    text: footer.value.email,
    email: footer.value.email,
  },
]
async function goToPage(path: string) {
  if (!path) return
  await navigateTo({ path: `/${path}` })
}
</script>
<style lang="scss" scoped>
h4 {
  font-size: 18px;
  margin-bottom: 16px;
}
.second-footer-part {
  .b2b-button {
    margin-top: 10px;
    height: 60px;
    min-width: 70%;
    max-width: 80%;
    font-size: 14px;
  }
}
.footer-divider {
  border: 1px solid rgb(var(--v-theme-border));
}
</style>
